import { useState, useEffect } from 'react';

import './App.css';
import CardList from './CardList.js';
import SearchBox from './SearchBox.js';

function App() {
  const [ searchfield, setSearchfield ] = useState('');
  const [ robots, setRobots ] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
    .then(response => response.json())
    .then(users => { setRobots(users) });
  }, [])

  const filteredRobots = robots.filter(robot => {
    return robot.name.toLowerCase().includes(searchfield.toLowerCase());
  })

  const onSearchBoxChange = (event) => {
    setSearchfield(event.target.value);
    console.log(event.target.value);
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>We are the robots!</h1>
      </header>
      <SearchBox searchChange={onSearchBoxChange}/>
      {
        robots.length ? <CardList robots={filteredRobots}/> : <div>Loading</div>
      }
      
    </div>
  );
}

export default App;
