function Card({id, name, email}) {
    return (
        <div style={{border: '1px solid black', borderRadius: '5px', background: 'lightcyan', padding: '5px', margin: '2px'}}>
            <img alt='robots' src={`https://robohash.org/${id}?size=200x200`} />
            <div>name: {name}</div>
            <div>email: {email}</div>
        </div>
    );
}

export default Card;