import Card from './Card.js';

function CardList({robots}) {
    return (
        <div style={{display: 'flex', padding: '5px', justifyContent: 'center', flexWrap: 'wrap'}}>
            {
                robots.map((robot, i) => {
                    return <Card key={i} id={robot.id} name={robot.name} email={robot.email}/>
                })
            }
        </div>
    );
}

export default CardList;